import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import { SharedDataService } from '../../services/shared-data/shared-data.service';
import { ConfigService } from '../../services/config/config.service';
import { LoadingService } from '../../services/loading/loading.service';
import {ModalController, NavParams} from '@ionic/angular';
import {Router} from "@angular/router";
import { ViewerModalComponent } from 'ngx-ionic-image-viewer';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.page.html',
  styleUrls: ['./product-detail.page.scss'],
})
export class ProductDetailPage implements OnInit {
  product_images;
  product;
  primary_color;
  @ViewChild("stylesContainer") stylesDist: ElementRef;
   slideOpts = {
    grabCursor: true
  }
   slideOpts2  = {
    initialSlide: 0,
    centeredSlides: false,
    spaceBetween: 15,
     threshold:10,
    breakpoints: {
      200: {
        slidesPerView: 1.3,
      },
      320: {
        slidesPerView: 2.3,
      },
      480: {
        slidesPerView: 2.3,
      },
      768: {
        slidesPerView: 3.3,
      },
      1024: {
        slidesPerView: 4.3,
      }
    }
  };
  constructor(
      public loading: LoadingService,
      public config: ConfigService,
      public shared: SharedDataService,
      public modalCtrl: ModalController,
      public navParams: NavParams,
      private renderer: Renderer2,
      public modalController: ModalController,
      public router: Router
  )
  {
    this.product = navParams.get('data');
    this.primary_color = navParams.get('primary_color');
    this.getProductDetail(this.product.product_id);
  }
  MarkupedPrice(cost,markup){
    return '$'+((parseFloat(cost)+parseFloat(markup)).toFixed(2));
  }
  ionViewWillEnter() {
    this.designerColor();

  }

  async zoomImage(img) {
    const modal = await this.modalController.create({
      component: ViewerModalComponent,
      componentProps: {
        src: img
      },
      cssClass: 'ion-img-viewer',
      keyboardClose: true,
      swipeToClose: true,
      showBackdrop: true,
      backdropDismiss: true,
    });

    return await modal.present();
  }
  designerColor() {
    var styleClass = "" ;
    styleClass += "app-product-detail ion-content{--background: "+this.primary_color+"cc !important;}";
    styleClass += ".create{--background: "+this.primary_color+" !important;}";
    styleClass += "@media (min-width: 768px) {.product-row{background: linear-gradient(0deg, "+this.primary_color+"63 30%, rgba(255,255,255,0) 30%);}}";
    styleClass += ".detail-title{background: "+this.primary_color+" !important;}";

    const classStyle = this.renderer.createText(styleClass);

    // Insert the previously defined style into a new <style> element
    const newStyleElement = this.renderer.createElement('style');
    this.renderer.appendChild(newStyleElement, classStyle);
    this.renderer.appendChild(this.stylesDist.nativeElement, newStyleElement);
  }
  getProductDetail(id) {
    this.loading.show();
    var dat: { [k: string]: any } = {};
    dat.id = id;
    this.config.postHttp2('getProductImages', dat).then((data: any) => {
      this.loading.hide();
      if (data.success == 1) {
        this.product_images= data.data;
      }
    });
  }
  //close modal
  dismiss() {
    this.modalCtrl.dismiss();
  }
  designer(product){
    this.loading.show();
    this.getDesign(product);
  }
  getDesign(product){
    this.shared.shopDetail.product=product;
    var dat: { [k: string]: any } = {};
    dat.product_id = product.product_id;
    dat.theme_id = product.theme_id;
    dat.language_id = this.config.langId;
    this.config.postHttp( 'getDesigns', dat).then((data: any) => {
      // this.loading.hide();
      if (data.success == 1) {
        this.shared.shopDetail.collections=data.data;
        this.dismiss();
        this.loading.hide();
        this.router.navigateByUrl("designer/" + this.product.theme_id + "/" + product.product_id);
      }
    });
  }
  ngOnInit() {
  }

}
