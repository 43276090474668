import { Plugins } from '@capacitor/core';
import { SeoService } from './utils/seo/seo.service';
const { SplashScreen } = Plugins;
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { HistoryHelperService } from './utils/history-helper.service';
import {Component, NgZone, ViewChildren, QueryList,Inject, PLATFORM_ID} from '@angular/core';
import {
  Platform,
  NavController,
  ModalController,
  MenuController,
  IonRouterOutlet,
  AlertController, ToastController
} from '@ionic/angular';

import { ConfigService } from './services/config/config.service';
import { SharedDataService } from './services/shared-data/shared-data.service';
import { LoadingService } from './services/loading/loading.service';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import {BehaviorSubject} from "rxjs";
import {isPlatformBrowser} from "@angular/common";
import { LOCAL_STORAGE } from '@ng-toolkit/universal';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [
    './side-menu/styles/side-menu.scss',
    './side-menu/styles/side-menu.shell.scss',
    './side-menu/styles/side-menu.responsive.scss'
  ]
})
export class AppComponent {
  static isBrowser = new BehaviorSubject<boolean>(null);
  appPages2 = [
    {
      title: 'Home',
      url: '/app/home',
      ionicIcon: 'list-outline'
    },
    {
      title: 'Categories',
      url: '/app/categories',
      ionicIcon: 'list-outline'
    },
    {
      title: 'Profile',
      url: '/app/user',
      ionicIcon: 'person-outline'
    },
    {
      title: 'Contact Card',
      url: '/contact-card',
      customIcon: './assets/custom-icons/side-menu/contact-card.svg'
    },
    {
      title: 'Notifications',
      url: '/app/notifications',
      ionicIcon: 'notifications-outline'
    }
  ];
  accountPages = [
    {
      title: 'Log In',
      url: '/auth/login',
      ionicIcon: 'log-in-outline'
    },
    {
      title: 'Sign Up',
      url: '/auth/signup',
      ionicIcon: 'person-add-outline'
    },
    {
      title: 'Tutorial',
      url: '/walkthrough',
      ionicIcon: 'school-outline'
    },
    {
      title: 'Getting Started',
      url: '/getting-started',
      ionicIcon: 'rocket-outline'
    },
    {
      title: '404 page',
      url: '/page-not-found',
      ionicIcon: 'alert-circle-outline'
    }
  ];

  textDir = 'ltr';

  rootPage: any;
  appPages = [];
  // For all pages
  a1 = [
    {
      name: 'Home',
      icon: 'home',
      url: 'home',
      img: 'assets/left-menu-icon/home.png',
      items: [
        { name: "1", url: '/home', },
        { name: "2", url: '/home2', },
        { name: "3", url: '/home3', },
        { name: "4", url: '/home4', },
        { name: "5", url: '/home5', },
        { name: "6", url: '/home6', },
        { name: "7", url: '/home7', },
        { name: "8", url: '/home8', },
        { name: "9", url: '/home9', },
        { name: "10", url: '/home10', }
      ],
      expanded: false
    },
    {
      name: 'Categories',
      icon: 'apps',
      url: 'categories/0/0',
      img: 'assets/left-menu-icon/category.png',
      items: [
        { name: "1", url: '/categories/0/0' },
        { name: "2", url: '/categories2/0/0' },
        { name: "3", url: '/categories3/0/0' },
        { name: "4", url: '/categories4/0/0' },
        { name: "5", url: '/categories5/0/0' },
        { name: "6", url: '/categories6/0/0' }
      ], expanded: false
    },
    {
      name: 'Shop',
      icon: 'cash',
      url: '/products/0/0/newest',
      img: 'assets/left-menu-icon/shop.png',
      items: [
        { name: "Newest", url: '/products/0/0/newest' },
        { name: "Top Seller", url: '/products/0/0/top seller' },
        { name: "Deals", url: '/products/0/0/special' },
        { name: "Most Liked", url: '/products/0/0/most liked' }
      ],
      expanded: false
    },
  ];
  a2 = [
    { name: 'Home', icon: 'home', url: 'home', img: 'assets/left-menu-icon/home.png' },
    { name: 'Categories', icon: 'apps', url: 'categories', img: 'assets/left-menu-icon/category.png' },
    {
      name: 'Shop', icon: 'cash', url: '/products', img: 'assets/left-menu-icon/shop.png',
      items: [
        { name: "Newest", url: '/products/0/0/newest' },
        { name: "Top Seller", url: '/products/0/0/top seller' },
        { name: "Deals", url: '/products/0/0/special' },
        { name: "Most Liked", url: '/products/0/0/most liked' }
      ],
      expanded: false
    }];
  a3 = [
    { name: 'My Wish List', icon: 'heart', img: 'assets/left-menu-icon/wishlist.png', url: '/wish-list', value: 'wishListPage' },
    { name: 'Contact Us', icon: 'call', img: 'assets/left-menu-icon/phone.png', url: '/contact-us', value: 'contactPage' },
    { name: 'About Us', icon: 'information-circle', img: 'assets/left-menu-icon/about.png', url: '/about-us', value: 'aboutUsPage' },
    { name: 'News', icon: 'newspaper', img: 'assets/left-menu-icon/news.png', url: '/news', value: 'newsPage' },
    { name: 'Intro', icon: 'logo-ionic', img: 'assets/left-menu-icon/intro.png', url: '/intro', value: 'introPage' },
    { name: 'Share', icon: 'share', img: 'assets/left-menu-icon/share.png', url: 'share', value: 'sharePage' },
    { name: 'Rate Us', icon: 'star-half', img: 'assets/left-menu-icon/rating.png', url: 'rateUs', value: 'ratePage' },
    { name: 'Settings', icon: 'settings', img: 'assets/left-menu-icon/setting.png', url: '/settings', value: 'settingsPage' }
  ];
  a4 = [
    { name: 'My Wish List', icon: 'heart', img: 'assets/left-menu-icon/wishlist.png', url: '/wish-list', value: 'wishListPage' },
    { name: 'Edit Profile', icon: 'lock-closed', img: 'assets/left-menu-icon/locked.png', url: '/my-account', login: true, value: 'editPage' },
    { name: 'Address', icon: 'locate', img: 'assets/left-menu-icon/map.png', url: '/addresses', login: true, value: 'addressesPage' },
    { name: 'My Orders', icon: 'cart', img: 'assets/left-menu-icon/orders.png', url: '/my-orders', login: true, value: 'myOrdersPage' },
    { name: 'Contact Us', icon: 'call', img: 'assets/left-menu-icon/phone.png', url: '/contact-us', value: 'contactPage' },
    { name: 'About Us', icon: 'information-circle', img: 'assets/left-menu-icon/about.png', url: '/about-us', value: 'aboutUsPage' },
    { name: 'News', icon: 'newspaper', img: 'assets/left-menu-icon/news.png', url: '/news', value: 'newsPage' },
    { name: 'Intro', icon: 'logo-ionic', img: 'assets/left-menu-icon/intro.png', url: '/intro', value: 'introPage' },
    { name: 'Share', icon: 'share', img: 'assets/left-menu-icon/share.png', url: 'share', value: 'sharePage' },
    { name: 'Rate Us', icon: 'star-half', img: 'assets/left-menu-icon/rating.png', url: 'rateUs', value: 'ratePage' },
    { name: 'Settings', icon: 'settings', img: 'assets/left-menu-icon/setting.png', url: '/settings', value: 'settingsPage' }
  ];
  isBannerShown=false;

  // Inject HistoryHelperService in the app.components.ts so its available app-wide
  constructor(
    public translate: TranslateService,
    public historyHelper: HistoryHelperService,
    public config: ConfigService,
    public shared: SharedDataService,
    public router: Router,
    public storage: Storage,
    public loading: LoadingService,
    public plt: Platform,
    private seoService: SeoService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    AppComponent.isBrowser.next(isPlatformBrowser(platformId));
    // document.documentElement.dir = localStorage.direction;
    // shared.dir = localStorage.direction;

    this.initializeApp();
    this.setLanguage();

  }


  async initializeApp() {
    this.plt.ready().then(() => {
      this.config.siteSetting().then((value) => {
      });
    });
  }

  setLanguage() {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use('en');

    // this is to determine the text direction depending on the selected language
    // for the purpose of this example we determine that only arabic and hebrew are RTL.
    // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    //   this.textDir = (event.lang === 'ar' || event.lang === 'iw') ? 'rtl' : 'ltr';
    // });
  }

}
