import { NgModule, Directive, ElementRef } from '@angular/core';
@Directive({
  selector: '[appScrollbarTheme]'
})
export class ScrollbarThemeDirective {
  constructor(el: ElementRef) {
    const stylesheet = `
        ::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        ::-webkit-scrollbar-button {
          width: 0px;
          height: 0px;
        }
        ::-webkit-scrollbar-thumb {
          background: #e1e1e1;
          border: 0px none #ffffff;
          border-radius: 50px;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: #ffffff;
        }
        ::-webkit-scrollbar-thumb:active {
          background: #000000;
        }
        ::-webkit-scrollbar-track {
          background: #666666;
          border: 0px none #ffffff;
          border-radius: 50px;
        }
        ::-webkit-scrollbar-track:hover {
          background: #666666;
        }
        ::-webkit-scrollbar-track:active {
          background: #333333;
        }
        ::-webkit-scrollbar-corner {
          background: transparent;
        }
    `;

    const styleElmt = el.nativeElement.shadowRoot.querySelector('style');
    if (styleElmt) {
      styleElmt.append(stylesheet);
    } else {
      const barStyle = document.createElement('style');
      barStyle.append(stylesheet);
      el.nativeElement.shadowRoot.appendChild(barStyle);
    }

  }
}
