<div class="campaign-component" *ngIf="shared.shops.length!=0">
  <div *ngIf="shared.shops[0]==1">
    <ion-title><ion-skeleton-text animated style="width: 100px;height: 20px;"></ion-skeleton-text></ion-title>
    <ion-slides  #shopSlider  [options]="slideOpts" [class]="config.shopStyle" >
      <ion-slide *ngFor="let b of shared.shops" class="ion-no-padding">
        <ion-skeleton-text animated style="width: 100%;height: 120px"></ion-skeleton-text>
      </ion-slide>
    </ion-slides>
  </div>
  <div *ngIf="shared.shops[0]!=1">
    <ion-title>{{'Campaigns'| translate }}</ion-title>
    <ion-slides  #shopSlider  [options]="slideOpts" [class]="config.shopStyle">
      <ion-slide [attr.data-shopid]="b.id" *ngFor="let b of shared.shops.campaigns" class="ion-no-padding" [class.campaign-end]="b.status==2" [ngStyle]="{'background': b.cover.background}" (click)="shopClick(b)">

        <ion-row>
         <ion-col size="5">
           <ion-skeleton-text  animated  [style.display]="b.loaded ? 'none' : 'unset'" ></ion-skeleton-text>
           <ion-img class="slide-img" id="slide-img-{{b.id}}" [src]="shared.path+b.shop_icon" [style.opacity]="b.loaded ? 1 : 0" (ionImgDidLoad)="b.loaded = true"></ion-img>
         </ion-col >
          <ion-col class="detail" size="7" >
            <div class="title">{{b.name}}</div>
            <div class="sub-title">{{b.sub_title}}</div>
            <div class="date">
              <ion-datetime class="start-date" disabled displayFormat="DD/MM"  value="{{b.start_date}}" ></ion-datetime>
              <ion-datetime class="end-date" disabled displayFormat="-DD/MM" value="{{b.end_date}}" ></ion-datetime>
            </div>
          </ion-col>
        </ion-row>

        <ion-icon name="calendar"></ion-icon>
      </ion-slide>
    </ion-slides>
  </div>
</div>