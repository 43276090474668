
<div class="shop-component" *ngIf="shared.shops.length!=0">
  <div class="shell" *ngIf="shared.shops[0]==1">
    <ion-title><ion-skeleton-text animated style="width: 100px;height: 20px;"></ion-skeleton-text></ion-title>
    <ion-slides  #shopSlider  [options]="slideOpts" [class]="config.shopStyle" >
      <ion-slide *ngFor="let b of shared.shops" class="ion-no-padding">
        <ion-skeleton-text animated style="width: 100%;height: 200px"></ion-skeleton-text>
      </ion-slide>
    </ion-slides>
  </div>
  <div *ngIf="shared.shops[0]!=1">
    <ion-title>{{'Featured Brands'| translate }}</ion-title>
    <ion-slides  #shopSlider  [options]="slideOpts" [class]="config.shopStyle" >
      <ion-slide [attr.data-shopid]="b.id" *ngFor="let b of shared.shops.brands" class="ion-no-padding" [ngStyle]="{'background': b.cover.background}" (click)="b.parent_id==''?shopClick(b.id):shopClick(b.parent_id)">
        <app-aspect-ratio class="slide-img" [ratio]="{w: 1, h: 1}">
          <app-image-shell  animation="gradient"  id="slide-img-{{b.id}}" [src]="shared.path+b.shop_icon"></app-image-shell>
        </app-aspect-ratio>
        <div class="slide-img-logo" >
          <app-image-shell  animation="gradient"  id="slide-img-logo-{{b.id}}" [src]="shared.path+b.logo"></app-image-shell>
        </div>
        <div class="footer"  >
          <div class="title">{{b.name}}<div [hidden]="! b.trademark" class="trademark">TM</div></div>
          <div class="sub-title">Flagship Store</div>
        </div>
        <ion-icon name="cart"></ion-icon>
      </ion-slide>
    </ion-slides>
  </div>
</div>