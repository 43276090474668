<div class="artist-component" *ngIf="shared.shops.length!=0">
  <div *ngIf="shared.shops[0]==1">
    <ion-title><ion-skeleton-text animated style="width: 100px;height: 20px;"></ion-skeleton-text></ion-title>
    <ion-slides  #shopSlider  [options]="slideOpts" [class]="config.shopStyle" >
      <ion-slide *ngFor="let b of shared.shops" class="ion-no-padding">
        <ion-skeleton-text animated style="width: 100%;height: 120px"></ion-skeleton-text>
      </ion-slide>
    </ion-slides>
  </div>
  <div *ngIf="shared.shops[0]!=1">
    <ion-title>{{'Fetured Artists'| translate }}</ion-title>
    <ion-slides  #shopSlider  [options]="slideOpts" [class]="config.shopStyle" >
      <ion-slide [attr.data-shopid]="b.id" *ngFor="let b of shared.shops.artists" class="ion-no-padding" [ngStyle]="{'background': b.primary_color}" (click)="b.parent_id==''?shopClick(b.id):shopClick(b.parent_id)">
        <ion-row>
          <ion-skeleton-text animated  [style.display]="b.loaded ? 'none' : 'unset'" ></ion-skeleton-text>
          <ion-img class="slide-img" id="slide-img-{{b.id}}" [src]="shared.path+b.shop_icon" [style.opacity]="b.loaded ? 1 : 0" (ionImgDidLoad)="b.loaded = true"></ion-img>
          <ion-col class="detail"  size="12" >
            <div class="title">{{b.name}}</div>
            <div class="sub-title">Hong Kong</div>
          </ion-col>
        </ion-row>
      </ion-slide>
    </ion-slides>
  </div>
</div>