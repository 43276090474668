<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{'Shipping Address'|translate}}</ion-title>

  </ion-toolbar>

</ion-header>

<ion-content>
  <form #loginForm="ngForm">
    <ion-row>
      <ion-col>
        <ion-list>
          <ion-item>
            <ion-input type="text" placeholder="{{'First Name'|translate}}" name="firstname"
              [(ngModel)]="shippingData.entry_firstname" required></ion-input>
          </ion-item>

          <ion-item>
            <ion-input type="text" placeholder="{{'Last Name'|translate}}" name="lastname"
              [(ngModel)]="shippingData.entry_lastname" required></ion-input>
          </ion-item>
          <ion-item>
            <ion-input type="text" placeholder="{{'Address'|translate}}" name="street"
              [(ngModel)]="shippingData.entry_street_address" required></ion-input>
          </ion-item>
          <ion-item>
            <ion-input type="text" placeholder="{{'Country/Region'|translate}}" name="country_name" (click)="selectCountryPage()"
              [readonly]="true" [(ngModel)]="shippingData.entry_country_name" required></ion-input>
          </ion-item>
          <ion-item>
            <ion-input type="text" placeholder="{{'Zone'|translate}}" required name="zone_name" (click)="selectZonePage()"
              [readonly]="true" [(ngModel)]="shippingData.entry_zone"></ion-input>
          </ion-item>
          <ion-item>
            <ion-input type="text" placeholder="{{'City'|translate}}" name="city" [(ngModel)]="shippingData.entry_city"
              required></ion-input>
          </ion-item>
          <ion-item>
            <ion-input type="text" placeholder="{{'Post code'|translate}}" name="postcode"
              [(ngModel)]="shippingData.entry_postcode" required></ion-input>
          </ion-item>

        </ion-list>
      </ion-col>
    </ion-row>

  </form>
  <ion-button expand="full" color="secondary" *ngIf="type=='update'" (click)="updateShippingAddress()"
    [disabled]="!loginForm.form.valid">{{'Update Address'|translate}}</ion-button>
  <ion-button expand="full" color="secondary" *ngIf="type=='add'" (click)="addShippingAddress()"
    [disabled]="!loginForm.form.valid">{{'Save Address'|translate}}</ion-button>
</ion-content>