import { Component, OnInit } from '@angular/core';
import { SharedDataService } from '../../services/shared-data/shared-data.service';
import {NavController} from "@ionic/angular";
import {PopoverController} from '@ionic/angular';

@Component({
  selector: 'app-popover-menu',
  templateUrl: './popover-menu.component.html',
  styleUrls: ['./popover-menu.component.scss'],
})
export class PopoverMenuComponent implements OnInit {

  constructor(
      public shared: SharedDataService,
      public navCtrl: NavController,
      private popover:PopoverController
  ) { }

  ngOnInit() {}

  products(cat_name,cat_id) {
    this.shared.category=cat_name;
    this.navCtrl.navigateForward("app/c/" + cat_id);
    this.popover.dismiss();
  }

}
