<ion-header solid #header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button  (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-no-padding">{{"Select Currency"|translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-radio-group [(ngModel)]="currency" (ionChange)="updateCurrency()">
      <ion-item *ngFor="let v of currencyList">
        <ion-label>{{v.title}}({{v.symbol_left}}{{v.symbol_right}})</ion-label>
        <ion-radio [value]="v" ></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</ion-content>