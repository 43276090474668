<ion-header solid #header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button  (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-no-padding">{{"Select Language"|translate}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list>
    <ion-radio-group [(ngModel)]="selectedLanguage" (ionChange)="updateLanguage(selectedLanguage)">
      <ion-item *ngFor="let l of languages">
        <ion-img src="{{config.imgUrl+l.image}}" class="ion-padding-end"></ion-img>
        <ion-label>{{l.name}}</ion-label>
        <ion-radio [value]="l" ></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</ion-content>