<div class="product-component animate-item">
  <div class="top-pick"  *ngIf="shared.topPicksProducts[0]==1">
    <ion-row class="content-container">
      <ion-col class="top-pick-header col" size="12">
        <div class="title"><ion-skeleton-text animated style="width: 120px;height: 20px;"></ion-skeleton-text></div>
        <div class="nav">
          <div class="arrow-left"><ion-icon name="chevron-back"></ion-icon></div>
          <div class="arrow-right"><ion-icon name="chevron-forward"></ion-icon></div>
        </div>
      </ion-col>
      <ion-col  class="products col" size="12" >
        <ion-slides  #shopSlider  [options]="slideOpts" [class]="config.shopStyle" >
          <ion-slide *ngFor="let b of shared.topPicksProducts" class="ion-no-padding">
            <ion-skeleton-text animated style="width: 100%;height: 220px"></ion-skeleton-text>
          </ion-slide>
        </ion-slides>
      </ion-col>
    </ion-row>
  </div>
  <ion-row class="top-pick" *ngIf="shared.topPicksProducts[0]!=1">
    <ion-row class="content-container">
      <ion-col class="top-pick-header col" size="12">
        <div class="title">{{'Top Picks'| translate }}</div>
        <div class="nav">
          <div class="arrow-left"><ion-icon name="chevron-back"></ion-icon></div>
          <div class="arrow-right"><ion-icon name="chevron-forward"></ion-icon></div>
        </div>
      </ion-col>
      <ion-col id="wrapperBoxes" class="products col" size="12" >
        <ion-slides pager="true"   [options]="slideOpts" >
          <ng-container *ngFor="let p of shared.topPicksProducts">
            <ion-slide class="product ion-no-padding animate-item"  >
              <div class="ion-activatable full-width" >
                <div class="shop-name">{{p.shop_name}}</div>
                <div class="shop-logo"><ion-img [src]="shared.path+p.logo"></ion-img> </div>
                <div class="product-img" (click)="designer(p)">
                  <app-aspect-ratio [ratio]="{w: 1, h: 1}">
                    <app-image-shell class="slide-img" animation="gradient"  [src]="shared.path+p.image"></app-image-shell>
                  </app-aspect-ratio>
                </div>
                <div class="product-name" (click)="designer(p)">{{p.products_name}}</div>
                <div class="product-price" (click)="designer(p)">{{MarkupedPrice(p.products_price,p.markup)  }}</div>
                <ion-icon name="cart" (click)="designer(p)"></ion-icon>
                <ion-icon name="information-circle-outline" class="info" (click)="openProductDetail(p)"></ion-icon>
              </div>
            </ion-slide>
          </ng-container>
        </ion-slides>
      </ion-col>
    </ion-row>
  </ion-row>

</div>