import {Component, OnInit, ViewChild, ViewEncapsulation,} from '@angular/core';
import { SharedDataService } from '../../services/shared-data/shared-data.service';
import { AnimationController,NavController } from '@ionic/angular';
import { ConfigService } from '../../services/config/config.service';
import { LoadingService } from '../../services/loading/loading.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./shop.component.scss'],
})
export class ShopComponent implements OnInit {
  // @ts-ignore
  @ViewChild('shopSlider') slider: ElementRef;
  _leaveAnimation;
  leave=false;
  animation_finish=false;

  public slideOpts: { [k: string]: any } = {
    autoplay: {
      delay: 2500,
      //disableOnInteraction: false,
    }
  };

  constructor(
      public shared: SharedDataService,
      public navCtrl: NavController,
      public config: ConfigService,
      public loading: LoadingService,
      private animationCtrl: AnimationController
  ) {
  }

  async leaveAnimation(id) {
    this._leaveAnimation = this.animationCtrl.create()
        .addElement(document.querySelector('.leaving-block'))
        .duration(300)
        .fromTo('transform', "translateY(100%)", "translateY(0%)")
        .onFinish(() => {
          this.animation_finish = true;
          this.shop(id);
          this._leaveAnimation.stop
        });
    this._leaveAnimation.play();

  }
  restAnimation(){
    if(this._leaveAnimation!=null){
      this.animation_finish=false;
      this._leaveAnimation.stop();
    }
  }
  //===============================================================================================
  //on click image shops
  shopClick = function (shop_id) {
    this.goToShop(shop_id);
    // this.getShopDetail(shop_id);
  }

  getIndex(e){
    let shopid= document.querySelector('.swiper-container .swiper-slide-active').getAttribute('data-shopid');
    this.shopClick(shopid);
  }
  //===============================================================================================
  //getting single product data
  getShopDetail(shop_id) {
    this.leave=false;
    this.animation_finish=false;
    this.leaveAnimation(shop_id);
    // this.loading.show();
    var dat: { [k: string]: any } = {};
    dat.id = shop_id;
    dat.language_id = this.config.langId;
    this.config.postHttp2( 'shopDetail', dat).then((data: any) => {
      // this.loading.hide();
      if (data.success == 1) {
        this.shared.shopDetail=data.data;
        this.leave=true;
        this.shop(data.data.id);
      }
    });
  }

  goToShop(shop_id) {
    this.leave=true;
    this.animation_finish=false;
    this.leaveAnimation(shop_id);
  }
  shop(id){
    if(this.animation_finish&&this.leave){
      // this.shared.shopDetail.preload=true;
      this.navCtrl.navigateForward("app/shop/" + id);
    }
  }


  ngOnInit() {
    var slidesPerView;

    this.slideOpts = {
      initialSlide: 0,
      centeredSlides: false,
      spaceBetween: 30,
      slidesOffsetBefore:30,
      slidesOffsetAfter:30,
      threshold:10,
      direction: 'horizontal',
      breakpoints: {
        320: {
          slidesPerView: 2.3,
        },
        480: {
          slidesPerView: 3.3,
        },
        640: {
          slidesPerView: 4.3,
          spaceBetween: 40
        },
        768: {
          slidesPerView: 4.3,
          spaceBetween: 40
        }
      }
    };


    // if (this.config.shopAnimationEffect == 'fade') this.applyFadeEffect();
    // else if (this.config.shopAnimationEffect == 'cube') this.applyCubeEffect();
    // else if (this.config.shopAnimationEffect == 'flip') this.applyFlipEffect();
    // else if (this.config.shopAnimationEffect == 'coverFlow') this.applyCoverFlowEffect();


    if (this.config.shopStyle == "bottomBulletsWhiteBackground") {
      this.slideOpts.pagination = {
        el: '.swiper-pagination',
        dynamicBullets: true,
      }
    }

    if (this.config.shopStyle == "progressBar") {
      this.slideOpts.pagination = {
        el: '.swiper-pagination',
        type: 'progressbar',
        progressbarOpposite: false
      }
    }

    if (this.config.shopStyle == "numberBullets") {
      this.slideOpts.pagination = {
        el: '.swiper-pagination',
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + '</span>';
        }
      }
    }
  }
}
