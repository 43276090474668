import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FadeHeaderDirective } from './fade-header.directive';
import { ScrollbarThemeDirective } from './scrollbar-theme.directive';

@NgModule({
  declarations: [FadeHeaderDirective, ScrollbarThemeDirective],
  imports: [
    CommonModule
  ],
  exports:[
    FadeHeaderDirective,
    ScrollbarThemeDirective
  ]
})
export class SharedDirectivesModule { }
