import {Component, OnInit, ApplicationRef, Input} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../services/config/config.service';
import { ModalController, NavController, NavParams,Platform } from '@ionic/angular';
import { SharedDataService } from '../../services/shared-data/shared-data.service';
import { SignUpPage } from '../sign-up/sign-up.page';
import { ForgotPasswordPage } from '../forgot-password/forgot-password.page';
import { LoadingService } from '../../services/loading/loading.service';
import { SocialAuthService,SocialUser  } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";




@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.page.html',
  styleUrls: ['./order-status.page.scss'],
})
export class OrderStatusPage implements OnInit {

  formData = { email: '', password: '' };
  otp="";
  errorMessage = '';
  hideGuestLogin: true;
  user: SocialUser;
  submit_btn:boolean=false;
  loggedIn: boolean;
  loggedPost=false;
  return_url="";
  orderStatus=[];
  @Input() error_msg:string;

  constructor(

    public config: ConfigService,
    public modalCtrl: ModalController,
    public loading: LoadingService,
    public shared: SharedDataService,
    private applicationRef: ApplicationRef,
    public navCtrl: NavController,
    public navParams: NavParams,
    private authService: SocialAuthService,
    public platform: Platform,
    private http: HttpClient,
  ) {
    this.hideGuestLogin = navParams.get('hideGuestLogin');
    this.return_url = navParams.get('return_url');
    this.shared.currentOpenedModel = this;
  }
  ngAfterViewInit() {
    const myContent = document.querySelector('#loginModal');
    this.shared.styleScrollbars(myContent);
    this.getOrderStatus();
    console.log(this.error_msg);
  }
  getOrderStatus(){
    this.loading.show();
    var dat: { [k: string]: any } = {};
    this.http.get( 'https://api.mosaicwall.online/orderStatus', dat).subscribe((data: any) => {
      this.loading.hide();
      this.orderStatus=data;
    }, (err) => {
      setTimeout(() => {
        this.getOrderStatus();
      }, 2000);
    });

  }

  //close modal
  dismiss() {
    this.modalCtrl.dismiss();
    if(this.return_url!=""&&this.return_url!=undefined){
      this.navCtrl.navigateForward(this.config.currentRoute + '/'+this.return_url);
    }
  }

  close() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {

  }

  next(){
    this.modalCtrl.dismiss();
  }

}
