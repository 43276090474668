import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { ShellModule } from '../shell/shell.module';

import { CheckboxWrapperComponent } from './checkbox-wrapper/checkbox-wrapper.component';
import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';
import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';
import { CounterInputComponent } from './counter-input/counter-input.component';
import { RatingInputComponent } from './rating-input/rating-input.component';
import { GoogleMapComponent } from './google-map/google-map.component';
import { ShopComponent } from './shop/shop.component';
import { CampaignComponent } from './campaign/campaign.component';
import { PopoverMenuComponent } from './popover-menu/popover-menu.component';
import { ArtistComponent } from './artist/artist.component';
import { ProductComponent } from './product/product.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ShellModule,
    IonicModule,
    PipesModule
  ],
  declarations: [
    CheckboxWrapperComponent,
    ShowHidePasswordComponent,
    CountdownTimerComponent,
    CounterInputComponent,
    RatingInputComponent,
    GoogleMapComponent,
    ShopComponent,
    CampaignComponent,
    ArtistComponent,
    ProductComponent,
    ColorPickerComponent,
    PopoverMenuComponent
  ],
  exports: [
    ShellModule,
    CheckboxWrapperComponent,
    ShowHidePasswordComponent,
    CountdownTimerComponent,
    CounterInputComponent,
    RatingInputComponent,
    GoogleMapComponent,
    ShopComponent,
    CampaignComponent,
    ArtistComponent,
    ProductComponent,
    ColorPickerComponent
  ]
})
export class ComponentsModule {}
