import {Component, OnInit, ApplicationRef, Input} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../services/config/config.service';
import { ModalController, NavController, NavParams,Platform } from '@ionic/angular';
import { SharedDataService } from '../../services/shared-data/shared-data.service';
import { SignUpPage } from '../sign-up/sign-up.page';
import { ForgotPasswordPage } from '../forgot-password/forgot-password.page';
import { LoadingService } from '../../services/loading/loading.service';
import { SocialAuthService,SocialUser  } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";




@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  formData = { email: '', password: '' };
  otp="";
  errorMessage = '';
  hideGuestLogin: true;
  user: SocialUser;
  submit_btn:boolean=false;
  loggedIn: boolean;
  loggedPost=false;
  return_url="";
  @Input() error_msg:string;

  constructor(

    public config: ConfigService,
    public modalCtrl: ModalController,
    public loading: LoadingService,
    public shared: SharedDataService,
    private applicationRef: ApplicationRef,
    public navCtrl: NavController,
    public navParams: NavParams,
    private authService: SocialAuthService,
    public platform: Platform,
  ) {
    this.hideGuestLogin = navParams.get('hideGuestLogin');
    this.return_url = navParams.get('return_url');
    this.shared.currentOpenedModel = this;
  }
  ngAfterViewInit() {
    const myContent = document.querySelector('#loginModal');
    this.shared.styleScrollbars(myContent);

    console.log(this.error_msg);
  }
  login() {
    this.loading.show();
    this.errorMessage = '';
    this.config.postHttp('processlogin', this.formData).then((data: any) => {
      this.loading.hide();
      if (data.success == 1) {
        this.shared.login(data.data[0]);
        this.dismiss();
      }
      if (data.success == 0) {
        this.errorMessage = data.message;
      }
    });
  }
  async openSignUpPage() {
    this.close();
    const modal = await this.modalCtrl.create({
      component: SignUpPage
    });
    return await modal.present();
  }
  async openForgetPasswordPage() {
    const modal = await this.modalCtrl.create({
      component: ForgotPasswordPage
    });
    return await modal.present();
  }

  signInWithFB(): void {
    this.loading.show();
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signInWithGoogle(): void {
    this.loading.show();
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.authService.signOut();
  }

  //============================================================================================  
  //creating new account using function facebook or google details 
  createAccount(user) {
    // alert(info);
    this.loading.hide();
    this.loading.show();
    var dat: { [k: string]: any } = {};
    var url = '';
    if (user.provider == 'FACEBOOK') {
      url = 'facebookregistration';
      dat.access_token = user.authToken;
    }
    else {
      url = 'googleregistration';
      dat = user;
    }
    this.config.postHttp(url, dat).then((data: any) => {
      this.loading.hide();
      // alert("data get");
      if (data.success == 1) {
        this.shared.login(data.data[0]);
        //alert('login');
        this.shared.showAlertWithTitle("<h3>Your Account has been created successfully !</h3><ul><li>Your Email: "
          + "<span>" + this.shared.customerData.email + "</span>" +
          " </li></ul><p>You can login using this Email and Password.<br>You can change your password in Menu -> My Account</p>", "Account Information");
        //  $ionicSideMenuDelegate.toggleLeft();

        this.loggedPost=false;
        this.dismiss();
      }
      else if (data.success == 2) {
        //  alert("login with alreday");
        this.dismiss();
        this.shared.login(data.data[0]);
      }
      this.loggedPost=false;
    }, error => {
      this.loading.hide();
      this.loggedPost=false;
      this.shared.showAlert("error " + JSON.stringify(error));
      // console.log("error " + JSON.stringify(error));
    });
  };
  //close modal
  dismiss() {
    this.modalCtrl.dismiss();
    if(this.return_url!=""&&this.return_url!=undefined){
      this.navCtrl.navigateForward(this.config.currentRoute + '/'+this.return_url);
    }
  }

  close() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {
    this.authService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);
      if(this.loggedIn && this.loggedPost==false) {
        this.loggedPost=true;
        this.createAccount(this.user);
      }
    });
  }

  guestLogin() {
    this.shared.changeGuestCheckoutStatus(1);
    //this.appEventsService.publish('openShippingAddressPage', "");
    this.dismiss();
  }
  onOtpChange(otp){
    this.error_msg="";
    this.submit_btn=false;
    if(otp.length==4){
      this.otp=otp;
      this.submit_btn=true;
    }
  }
  submit(){
    if(this.otp.length==4) {
      this.modalCtrl.dismiss(this.otp);
    }
  }

}
