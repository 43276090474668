<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{'Refund Policy'|translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-text>
    <p [innerHTML]="shared.refundPolicy">
    </p>
  </ion-text>
</ion-content>