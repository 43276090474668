
import { Injectable, ApplicationRef, NgZone } from '@angular/core';

import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { Platform, ToastController, AlertController } from '@ionic/angular';
import { SocialAuthService,SocialUser  } from "angularx-social-login";
import { ConfigService } from '../config/config.service';
import { LoadingService } from '../loading/loading.service';

@Injectable()
export class SharedDataService {

  public banners = [1, 1, 1, 1, 1];
  public shops = [1, 1, 1, 1, 1];
  public tab1 = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  public tab2 = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  public tab3 = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  public topPicksProducts = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  public flashSaleProducts = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  public allCategories: any = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  public categories: any = [1, 1, 1, 1, 1, 1];
  public category: any ;
  public subCategories: any = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  public customerData: { [k: string]: any } = {};
  public recentViewedProducts = new Array();
  public cartProducts = new Array();
  public privacyPolicy = "";
  public termServices = "";
  public refundPolicy = "";
  public aboutUs = "";
  public cartquantity = 0;
  public wishList = new Array();
  public tempdata: { [k: string]: any } = {};
  public dir = "ltr";
  public selectedFooterPage = "HomePage";
  public currentOpenedModel: any = null;
  public path='';

  public orderDetails = {
    guest_status: 0,
    email: "",
    tax_zone_id: "",
    delivery_firstname: "",
    delivery_lastname: "",
    delivery_state: "",
    delivery_city: "",
    delivery_postcode: "",
    delivery_zone: "",
    delivery_country: "",
    delivery_country_id: "",
    delivery_street_address: "",
    delivery_country_code: "",
    delivery_phone: "",

    billing_firstname: "",
    billing_lastname: "",
    billing_state: "",
    billing_city: "",
    billing_postcode: "",
    billing_zone: "",
    billing_country: "",
    billing_country_id: "",
    billing_street_address: "",
    billing_country_code: "",
    billing_phone: "",
    total_tax: '',
    shipping_cost: '',
    shipping_method: '',
    sfPickup: false,
    sfPickupAddress: '',
    payment_method: '',
    comments: '',
    shop_id: ''
  };
  public lastOrderDetails : any;
  public translationListArray = [];
  public singleProductPageData = [];
  public singlePostData: any;
  myOrderDetialPageData: any;
  lab = false;
  public missingValues = [];
  public shopDetail: any;
  primaryHexColor = "#3980ff";
  constructor(
      public config: ConfigService,
      public httpClient: HttpClient,
      public storage: Storage,
      public platform: Platform,
      public alertCtrl: AlertController,
      private toastCtrl: ToastController,
      private authService: SocialAuthService,
      public loading: LoadingService,
  ) {
    // setTimeout(() => {
    //   this.lab = true;
    // }, 10000);

    storage.get('customerData').then((val) => {
      if (val != null || val != undefined) this.customerData = val;
    });
    this.platform.ready().then(() => {
      // this.config.getHttp2("applabels3?lang=" + this.config.langId).then((data: any) => {
      //   this.translationListArray = data;
      // });
      this.onStart();
    });




    //getting recent viewed items from local storage
    // storage.get('recentViewedProducts').then((val) => {
    //   if (val != null) this.recentViewedProducts = val;
    // });
    // if (this.platform.is('cordova')) {
    // }
    //getting recent viewed items from local storage
    storage.get('cartProducts').then((val) => {
      if (val != null) this.cartProducts = val;
      this.cartTotalItems();
      // console.log(val);
    });


    //---------------- end -----------------


  }


  public splashScreenHide = false;
  hideSplashScreen() {
    if (this.platform.is('cordova')) {
      // if (!this.splashScreenHide) { this.splashScreen.hide(); this.splashScreenHide = true; }
    }
  }
  onStart() {
    //getting all banners
    // this.config.getHttp('getbanners?languages_id=' + this.config.langId).then((data: any) => {
    //   this.banners = data.data;
    // });

    // this.config.getHttp2('getShops?languages_id=' + this.config.langId).then((data: any) => {
    //   this.shops = data.data;
    // });
    // this.config.getHttp2('getCategories?languages_id=' + this.config.langId).then((data: any) => {
    //   this.categories = data.data;
    // });
    // let data2: { [k: string]: any } = {};
    // data2.language_id = this.config.langId;
    // this.config.postHttp2('getTopPicks?languages_id=' + this.config.langId,data2).then((data: any) => {
    //   this.topPicksProducts = data.data;
    // });

    //getting tab 1
    let data: { [k: string]: any } = {};
    if (this.customerData.customers_id != null)
      data.customers_id = this.customerData.customers_id;
    data.page_number = 0;
    data.language_id = this.config.langId;
    data.currency_code = this.config.currencyCode;

    let getdata= false;
    if(getdata){
      data.type = 'flashsale';
      this.config.postHttp('getallproducts', data).then((data: any) => {
        this.flashSaleProducts = data.product_data
      });
      data.type = 'top seller';
      this.config.postHttp('getallproducts', data).then((data: any) => {
        this.tab1 = data.product_data
      });
      //getting tab 2
      data.type = 'special';
      this.config.postHttp('getallproducts', data).then((data: any) => {
        this.tab2 = data.product_data
      });
      //getting tab 3
      data.type = 'most liked';
      this.config.postHttp('getallproducts', data).then((data: any) => {
        this.tab3 = data.product_data
      });
      //getting all allCategories
      this.config.postHttp('allcategories', data).then((data: any) => {
        if (this.allCategories[0] == 1) {
          this.allCategories = [];
          this.categories = [];
          this.subCategories = [];
        }
        for (let value of data.data) {

          value.id = value.categories_id;
          value.name = value.categories_name;

          this.allCategories.push(value);

          if (value.parent_id == 0)
            this.categories.push(value);
          else
            this.subCategories.push(value);
        }
      });

      //getting allpages from the server
      this.config.postHttp('getallpages', { language_id: this.config.langId, currency_code: this.config.currencyCode }).then((data: any) => {
        if (data.success == 1) {
          let pages = data.pages_data;
          for (let value of pages) {
            if (value.slug == 'privacy-policy') this.privacyPolicy = value.description;
            if (value.slug == 'term-services') this.termServices = value.description;
            if (value.slug == 'refund-policy') this.refundPolicy = value.description;
            if (value.slug == 'about-us') this.aboutUs = value.description;
          }
        }
      });
    }
  }
  //adding into recent array products
  addToRecent(p) {
    let found = false;
    for (let value of this.recentViewedProducts) {
      if (value.products_id == p.products_id) { found = true; }
    }
    if (found == false) {
      this.recentViewedProducts.push(p);
      this.storage.set('recentViewedProducts', this.recentViewedProducts);
    }
  }
  //removing from recent array products
  removeRecent(p) {
    this.recentViewedProducts.forEach((value, index) => {
      if (value.products_id == p.products_id) {
        this.recentViewedProducts.splice(index, 1);
        this.storage.set('recentViewedProducts', this.recentViewedProducts);
      }
    });
  }
  //adding into cart array products
  addToCart(product, attArray) {

    // console.log(this.cartProducts);
    let attributesArray = attArray;
    if (attArray.length == 0 || attArray == null) {
      //console.log("filling attirbutes");
      attributesArray = [];
      if (product.attributes != undefined) {
        // console.log("filling product default attibutes");
        product.attributes.forEach((value, index) => {
          let att = {
            products_options_id: value.option.id,
            products_options: value.option.name,
            products_options_values_id: value.values[0].id,
            options_values_price: value.values[0].price,
            price_prefix: value.values[0].price_prefix,
            products_options_values: value.values[0].value,
            name: value.values[0].value + ' ' + value.values[0].price_prefix + value.values[0].price + " " + this.config.currency
          };
          attributesArray.push(att);
        });
      }
    }
    //  if(checkDublicateService(product.products_id,$rootScope.cartProducts)==false){

    let pprice = parseFloat(product.products_price)+parseFloat(product.markup);
    let on_sale = false;
    if (product.discount_price != null) {
      pprice = product.discount_price;
      on_sale = true;
    }
    if (product.flash_price != null) {
      pprice = product.flash_price;
    }
    // console.log("in side producs detail");
    // console.log(attributesArray);
    // console.log(this.cartProducts);
    let finalPrice = this.calculateFinalPriceService(attributesArray) + pprice;
    let obj = {
      cart_id: product.products_id + this.cartProducts.length,
      products_id: product.products_id,
      manufacture: product.manufacturers_name,
      customers_basket_quantity: 1,
      final_price: finalPrice,
      model: product.products_model,
      categories: product.categories,
      // categories_id: product.categories_id,
      // categories_name: product.categories_name,
      // quantity: product.products_quantity,
      weight: product.products_weight,
      on_sale: on_sale,
      unit: product.products_weight_unit,
      image: product.products_image,
      attributes: attributesArray,
      products_name: product.products_name,
      price: pprice,
      subtotal: finalPrice,
      total: finalPrice,
      design_mockup: product.design_mockup,
      design_data: product.design_data,
      markup: product.markup,
      thank_you: product.thank_you,
      design_id: product.design_id,
      theme_id: product.theme_id,
      shop_id: product.shop_id
    }

    this.cartProducts.push(obj);
    this.storage.set('cartProducts', this.cartProducts);

    this.cartTotalItems();

    // console.log(this.cartProducts);
    //console.log(this.cartProducts);
  }
  //removing from recent array products
  removeCart(p) {
    this.cartProducts.forEach((value, index) => {
      if (value.cart_id == p) {
        this.cartProducts.splice(index, 1);
        this.storage.set('cartProducts', this.cartProducts);
      }
    });
    this.cartTotalItems();
  }
  emptyCart() {
    this.orderDetails.guest_status = 0;
    this.cartProducts = [];
    this.storage.set('cartProducts', this.cartProducts);
    this.cartTotalItems();
  }
  emptyRecentViewed() {
    this.recentViewedProducts = [];
    this.storage.set('recentViewedProducts', this.recentViewedProducts);
  }
  calculateFinalPriceService(attArray) {
    let total = 0;
    attArray.forEach((value, index) => {
      let attPrice = parseFloat(value.options_values_price);
      if (value.price_prefix == '+') {
        //  console.log('+');
        total += attPrice;
      }
      else {
        //  console.log('-');
        total -= attPrice;
      }
    });
    // console.log("max "+total);
    return total;
  }

  //Function calcualte the total items of cart
  cartTotalItems = function () {
    let total = 0;
    for (let value of this.cartProducts) {
      total += value.customers_basket_quantity;
    }
    this.cartquantity = total;
    // console.log("updated");
    return total;
  };

  removeWishList(p) {
    this.loading.show();
    let data: { [k: string]: any } = {};
    data.liked_customers_id = this.customerData.customers_id;
    data.liked_products_id = p.products_id;
    this.config.postHttp('unlikeproduct', data).then((data: any) => {
      this.loading.hide();
      if (data.success == 1) {
        p.isLiked = 0;
        this.wishList.forEach((value, index) => {
          if (value.products_id == p.products_id) this.wishList.splice(index, 1);
        });
      }
      if (data.success == 0) {

      }
    });
  }
  addWishList(p) {
    this.loading.show();
    let data: { [k: string]: any } = {};
    data.liked_customers_id = this.customerData.customers_id;
    data.liked_products_id = p.products_id;
    this.config.postHttp('likeproduct', data).then((data: any) => {
      this.loading.hide();
      if (data.success == 1) {
        p.isLiked = 1;
      }

      if (data.success == 0) { }
    });
  }


  login(data) {
    this.customerData = data;
    this.customerData.customers_telephone = data.phone;
    this.customerData.phone = data.phone;
    this.customerData.customers_id = data.id;
    this.customerData.customers_firstname = data.first_name;
    this.customerData.customers_lastname = data.last_name;
    this.customerData.phone = data.phone;
    this.customerData.avatar = data.avatar;
    this.customerData.image_id = data.image_id;
    this.customerData.customers_dob = data.dob;
    this.storage.set('customerData', this.customerData);
  }
  logOut() {
    this.authService.signOut();
    this.loading.autoHide(500);
    this.customerData = {};
    this.changeGuestCheckoutStatus(0);
    this.storage.set('customerData', this.customerData);
    // this.fb.logout();
  }
  changeGuestCheckoutStatus(value) {
    this.orderDetails.guest_status = value;
  }




  toast(msg, time = 3500) {
    this.translateString(msg).then(async (res: string) => {
      const toast = await this.toastCtrl.create({
        message: res,
        duration: time,
        position: 'bottom'
      });
      toast.present();
    });
  }
  toastMiddle(msg) {

    this.translateString(msg).then(async (res: string) => {
      let toast = await this.toastCtrl.create({
        message: res,
        duration: 3500,
        position: 'middle'
      });

      toast.present();
    });
  }

  toastWithCloseButton(msg) {

    this.translateString(msg).then(async (res: string) => {
      let toast = await this.toastCtrl.create({
        message: res,
        keyboardClose: true,
        position: 'middle',
        //text: "X"
      });
      toast.present();
    });
  }


  //categories page

  getCategoriesPageItems(parent) {
    let c = [];
    if (parent == undefined)
      c = this.categories;
    else {
      for (let v of this.allCategories) {
        if (v.parent == parent) {
          c.push(v);
        }
      }
    }
    return c;
  }

  // translation services
  translateString(value) {
    return new Promise(resolve => {
      let v = this.translationListArray[value];
      console.log(v);
      if (v == undefined) {
        this.missingValues[value] = value;
        v = value;
      }
      resolve(v);
    });
  }
  translateArray(value) {
    return new Promise(resolve => {
      let tempArray = [];
      value.forEach(element => {
        if (this.translationListArray[element] != undefined) {
          tempArray[element] = this.translationListArray[element];

        }
        else {
          tempArray[element] = element;
          this.missingValues[value] = value;
        }
      });
      resolve(tempArray);
    });
  }
  //=================================================

  showAlert(text) {
    this.translateArray([text, "ok", "Alert"]).then(async (res) => {
      console.log(res);
      const alert = await this.alertCtrl.create({
        header: res["Alert"],
        message: res[text],
        buttons: [res["ok"]]
      });
      await alert.present();
    });
  }

  showAlertWithTitle(text, title) {
    this.translateArray([text, "ok", title]).then(async (res) => {
      let alert = await this.alertCtrl.create({
        header: res[title],
        message: res[text],
        buttons: [res["ok"]]
      });
      await alert.present();
    });
  }

  getNameFirstLetter() {
    return this.customerData.first_name.charAt(0);
  }


  getProductRatingPercentage(rating: any) {
    let val = (parseFloat(rating) * 100) / 5;
    return val + '%'
  }

  styleScrollbars(elmt: any) {
    const stylesheet = `
        ::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        ::-webkit-scrollbar-button {
          width: 0px;
          height: 0px;
        }
        ::-webkit-scrollbar-thumb {
          background: #e1e1e1;
          border: 0px none #ffffff;
          border-radius: 50px;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: #ffffff;
        }
        ::-webkit-scrollbar-thumb:active {
          background: #000000;
        }
        ::-webkit-scrollbar-track {
          background: #666666;
          border: 0px none #ffffff;
          border-radius: 50px;
        }
        ::-webkit-scrollbar-track:hover {
          background: #666666;
        }
        ::-webkit-scrollbar-track:active {
          background: #333333;
        }
        ::-webkit-scrollbar-corner {
          background: transparent;
        }
    `;

    const styleElmt = elmt.shadowRoot.querySelector('style');

    if (styleElmt) {
      styleElmt.append(stylesheet);
    } else {
      const barStyle = document.createElement('style');
      barStyle.append(stylesheet);
      elmt.shadowRoot.appendChild(barStyle);

    }
  }
  send_mail(){

  }

}
