import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class GetDeviceIdService {
  deviceId: any = "";

  constructor(
      public platform: Platform,
  ) {

  }
  public realDeviceIdUpdatedCounter = 0;

  getDeviceId() {
    let id = "";
    if (this.realDeviceIdUpdatedCounter == 0) {
      let d = new Date();
      id = d.getTime().toString();
    }
    else {
      id = this.deviceId;
    }
    return id;
  }

}
