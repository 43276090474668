<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{'Zone'|translate}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="page-select-zones">
  <ion-searchbar (ionInput)="getItems($event)" placeholder="{{'Search'|translate}}" autofocus></ion-searchbar>
  <ion-list>
    <ion-item *ngFor="let item of items" (click)="selectZone(item)">
      {{ item.zone_name }}
    </ion-item>
    <ion-item (click)="selectZone('other')">
      {{'other'|translate}}
    </ion-item>
  </ion-list>
</ion-content>