import { Pipe, PipeTransform } from '@angular/core';
import { LocalstorageService } from '../services/localstorage/localstorage.service';

@Pipe({
  name: 'curency'
})
export class CurencyPipe implements PipeTransform {

  constructor(
      public localStorage: LocalstorageService) {
  }

  transform(value) {


    let currency =  this.localStorage.getItem('currency');
    let decimals = this.localStorage.getItem('decimals');
    let currecnyPos = this.localStorage.getItem('currencyPos');

    var priceFixed = parseFloat(value).toFixed(parseInt(decimals));

    if (priceFixed.toString() == 'NaN') {

      if (currecnyPos == 'left')
        return currency + "" + value;
      else
        return value + " " + currency;
    }
    else {
      if (currecnyPos == 'left')
        return currency + "" + priceFixed;
      else
        return priceFixed + "" + currency;
    }
  }

}
