import {Injectable} from '@angular/core';
import { LocalStorageService, SessionStorageService, SessionStorage } from 'angular-web-storage';


@Injectable({
    providedIn: 'root'
})
export class LocalstorageService {

    constructor(public local: LocalStorageService, private session: SessionStorageService) {
    }

    [name: string]: any;

    length: number;

    clear(): void {
        this.storage.clear();
    }

    getItem(key: string): string | null {
        return  this.local.get(key);
    }

    key(index: number): string | null {
        return this.storage.key(index);
    }
    setItem(key: string, value: string): void {
        return this.local.set(key, value, 1, 'y');
    }

    removeItem(key: string): void {
        return  this.local.remove(key);
    }
}