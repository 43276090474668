<ion-header solid #header class="ion-no-border">
  <ion-toolbar #toolbar fixed class="grid-fixed">
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{'Country/Region'|translate}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content  appScrollbarTheme  [fullscreen]="true" scrollEvents="true" [appFadeHeader]="toolbar">
  <ion-searchbar (ionInput)="getItems($event)" placeholder="{{'Search'|translate}}" #Searchbar></ion-searchbar>
  <ion-list>
    <ion-item *ngFor="let item of items" (click)="selectCountry(item)">
      {{ item.countries_name }}
    </ion-item>
  </ion-list>
</ion-content>