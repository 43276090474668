<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-no-padding">{{'Forgot Password'|translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-img src="assets/icons_stripe.svg"></ion-img>

  <form #loginForm="ngForm" class="form" (ngSubmit)="forgetPassword()">
    <ion-item>
      <ion-label position="floating">{{'Email'|translate}}</ion-label>
      <ion-input type="email" name="customers_email_address" [(ngModel)]="formData.email" required>
      </ion-input>
    </ion-item>

    <ion-item *ngIf="errorMessage!=''" lines="none">
      <ion-label>
        {{errorMessage| translate}}
      </ion-label>
    </ion-item>

    <ion-button disabled="true" expand="full" color="secondary" type="submit" [disabled]="!loginForm.form.valid">
      {{'Send'|translate}}
    </ion-button>
  </form>
</ion-content>