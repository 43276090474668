import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnInit {
  @Input('colorlist') colors;
  @Input('exdata') exdata;
  @Input('display_col') color_col;
  @Output() selectColor = new EventEmitter();
  text: string;
  colorsArray;
  colors_length;

  constructor() {
    if(this.color_col<0||this.color_col==null||this.color_col==undefined){
      this.color_col=1;
    }
  }
  onclick(color){
    var item=[{}];
    item['color']=color;
    item['exdata']=this.exdata;
    this.selectColor.emit(item)
  }
  ngOnInit() {}

}
