import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { SelectCountryPage } from './select-country.page';
import {PipesModule} from "../../pipes/pipes.module";
import {SharedDirectivesModule} from "../../directives/shared-directives.module";

const routes: Routes = [
  {
    path: '',
    component: SelectCountryPage
  }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        PipesModule,
        RouterModule.forChild(routes),
        SharedDirectivesModule,
    ],
  declarations: [SelectCountryPage]
})
export class SelectCountryPageModule {}
