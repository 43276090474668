<ion-header solid #header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start"><ion-button (click)="close()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content id="loginModal" class="ion-padding">
  <ion-title class="ion-no-padding">{{'Estimated wait time for your order'|translate}}</ion-title>


  <div class="estimated-time" lines="none" >
    <div class="title" hidden>{{'Estimated wait time for ordering'|translate}}</div>
    <div class="time" >{{orderStatus['waiting_time']}} {{'mins'|translate}}</div>
    <ion-icon name="timer-outline"></ion-icon>
  </div>
  <div class="current-no-title" hidden>{{'Current order number'|translate}}</div>
  <div class="current-no" lines="none" hidden >
    <div class="order-no">{{orderStatus['current_no']|translate}}</div>
  </div>
  <div class="footer">{{'This is for reference only but not the actual waiting time.'|translate}}</div>


  <ion-button class="submit"   type="submit" (click)="next()">
    {{ 'Back to my order' | translate }}
  </ion-button>


</ion-content>