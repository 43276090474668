
<ion-content>
  <div #stylesContainer></div>
  <div class="product-wrapper">
    <div class="product">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button size="large" class="back" fill="clear" (click)="dismiss()" [style.background]="'#ffffffa6'">
          <ion-icon name="chevron-back-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-button slot="end" class="create" shape="round" (click)="designer(product)" >{{"Create Now"|translate}}</ion-button>
    </ion-toolbar>

    <ion-row class="product-row">
      <ion-col sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6">
        <div class="product-main-img" >
          <app-aspect-ratio *ngIf="!product.mockup_design" class="slide-img" [ratio]="{w: 1, h: 1}">
            <app-image-shell  animation="gradient"  [src]="shared.path+product.image"></app-image-shell>
          </app-aspect-ratio>
          <app-aspect-ratio  *ngIf="product.mockup_design" class="slide-img" [ratio]="{w: 1, h: 1}">
            <app-image-shell  animation="gradient"  [src]="product.mockup_design"></app-image-shell>
          </app-aspect-ratio>
        </div>
      </ion-col>
      <ion-col sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="6"  >
        <div class="dt-gap ion-hide-md-down"></div>
        <div class="product-name" >{{product.products_name}}</div>
        <div class="theme" >-{{product.theme_name}}-</div>
        <div class="dash"></div>
        <div class="product-price" >{{MarkupedPrice(product.products_price,product.markup)}}</div>
        <div class="dash"></div>
        <div class="brand-logo">
          <p>{{"Brand"|translate}}:</p>
          <img [src]="shared.path+product.logo"/>
        </div>
      </ion-col>
    </ion-row>

      <div class="detail-title" >{{"Detail"|translate}}</div>
      <div class="detail-sub-title" >-{{product.theme_name}}-</div>
      <ion-grid>
        <ion-row >
          <ion-col>
            <div class="detail">
              <div class="product-desc" [innerHTML]='product.products_description'></div>
            </div>
          </ion-col>
        </ion-row>
        <ion-row >
          <ion-col sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="12">
            <ion-slides pager="true" [options]="slideOpts2">
              <ion-slide class=" ion-no-padding animate-item" *ngFor="let p of product_images" (click)="zoomImage(shared.path+p.image)">
                <div class="product-img">
                  <app-aspect-ratio class="slide-img" [ratio]="{w: 1, h: 1}">
                    <app-image-shell  animation="gradient"  [src]="shared.path+p.image"></app-image-shell>
                  </app-aspect-ratio>
                </div>
              </ion-slide>
            </ion-slides>
          </ion-col>
        </ion-row>
      </ion-grid>

  </div>
  </div>
</ion-content>
