import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { ModalController } from '@ionic/angular';
import { LoginPage } from '../../modals/login/login.page';
import { SharedDataService } from '../shared-data/shared-data.service';
import { Storage } from '@ionic/storage';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    public modalCtrl: ModalController,
    public shared: SharedDataService,
    private storage: Storage
  ) {
  }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return await this.storage.get('customerData').then((val) => {
      if (val != null || val != undefined) this.shared.customerData = val;

      if (this.shared.customerData.customers_id == null) {
        if (this.shared.orderDetails.guest_status == 0) {
          this.openLoginPage(route.data.hideGuestLogin, route.url);
          return false;
        } else if (this.shared.orderDetails.guest_status == 1 && route.data.hideGuestLogin == false) {
          return true;
        } else {

          this.openLoginPage(route.data.hideGuestLogin, state.url);
        }
      } else
        return true;
    });

  }

  async openLoginPage(value,return_url) {


    let val = value;
    if (value == undefined)
      val = true;



    let modal = await this.modalCtrl.create({
      component: LoginPage,
      componentProps: {
        'hideGuestLogin': val,
        'return_url': return_url
      }
    });
    return await modal.present();
  }
}
