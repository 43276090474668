<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-no-padding">{{'Create an Account'|translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <ion-avatar>
    <ion-img src="assets/avatar.png"></ion-img>
  </ion-avatar>

  <form #loginForm="ngForm" class="form" (ngSubmit)="registerUser()">
    <ion-row>
      <ion-col size="6">
        <ion-item>
          <ion-label position="floating">{{'First Name'|translate}}</ion-label>
          <ion-input type="text" name="customers_firstname" [(ngModel)]="formData.customers_firstname" required>
          </ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="6">
        <ion-item>
          <ion-label position="floating">{{'Last Name'|translate}}</ion-label>
          <ion-input type="text" name="customers_lastname" [(ngModel)]="formData.customers_lastname" required>
          </ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="6">
        <ion-item>
          <ion-label position="floating">{{'Phone Number'|translate}}</ion-label>
          <ion-input inputmode="tel" minlength=8 maxlength=15 pattern="^([0-9\(\)\/\+ \-]*)$" name="Mobile" [(ngModel)]="formData.customers_telephone" required>
          </ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="6">
        <ion-item>
          <ion-label position="floating">{{'Email'|translate}}</ion-label>
          <ion-input type="email" email name="email" [(ngModel)]="formData.email" required
                     pattern="[A-Za-z0-9._%+-]{2,}@[a-zA-Z]{2,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})">
          </ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-item>
          <ion-label position="floating">{{'Password'|translate}}</ion-label>
          <ion-input type="password" name="c_d" [(ngModel)]="formData.password" required>
          </ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-item *ngIf="errorMessage!=''" lines="none">
          <ion-label>
            {{errorMessage| translate}}
          </ion-label>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <p>{{"Creating an account means you’re okay with our"|translate}}
          <a (click)="openTermServicesPage()">{{'Term and Services'|translate}}</a>,
          <a (click)="openPrivacyPolicyPage()">{{'Privacy Policy'|translate}}</a> {{'and'|translate}}
          <a (click)="openRefundPolicyPage()">{{'Refund Policy'|translate}}</a>
        </p>
      </ion-col>
      <ion-col size="12">
        <ion-button disabled="true" expand="full" color="secondary" type="submit" [disabled]="!loginForm.form.valid">
          {{'Register'|translate}}
        </ion-button>
      </ion-col>
    </ion-row>

  </form>
</ion-content>