<ion-grid>
  <ion-title>Products</ion-title>
  <ion-row>
    <ion-col size="8">
      <ion-row class="menu">
        <ion-col  *ngFor="let cat of shared.categories" size="4" (click)="products(cat.categories_name,cat.categories_id)">
          <ion-icon name="chevron-forward-circle-outline"></ion-icon>
          {{cat.categories_name}}</ion-col>
      </ion-row>
    </ion-col>
    <ion-col size="4">
      <ion-searchbar animated showCancelButton="always"></ion-searchbar>
      <span>Enter your keyword here and then press enter</span>
    </ion-col>
  </ion-row>
</ion-grid>
