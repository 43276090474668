import {Component, OnInit, Input, ViewEncapsulation, ViewChild} from '@angular/core';
import {AnimationController, ModalController, NavController} from '@ionic/angular';
import { SharedDataService } from '../../services/shared-data/shared-data.service';
import { ConfigService } from '../../services/config/config.service';
import { LoadingService } from '../../services/loading/loading.service';
import {Router} from "@angular/router";
import {ProductDetailPage} from "../../modals/product-detail/product-detail.page";

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
// @ts-ignore
  @ViewChild('shopSlider') slider: ElementRef;
  _leaveAnimation;
  leave = false;
  animation_finish = false;

  public slideOpts: { [k: string]: any } = {
    initialSlide: 0,
    centeredSlides: false,
    spaceBetween: 30,
    slidesOffsetBefore:30,
    slidesOffsetAfter:30,
    threshold:10,
    breakpoints: {
      200: {
        slidesPerView: 1,
      },
      320: {
        slidesPerView: 1.8,
      },
      480: {
        slidesPerView: 2.3,
      },
      768: {
        slidesPerView: 3.3,
        spaceBetween: 40
      },
      1024: {
        slidesPerView: 4.3,
        spaceBetween: 40
      }
    }
  };

  constructor(
      public shared: SharedDataService,
      public navCtrl: NavController,
      public config: ConfigService,
      public loading: LoadingService,
      public router: Router,
      private animationCtrl: AnimationController,
      public modalCtrl: ModalController
  ) {
  }


  //===============================================================================================
  //on click image shops
  shopClick = function (shop_id) {
    this.getShopDetail(shop_id);
  }

  getIndex(e) {
    let shopid = document.querySelector('.swiper-container .swiper-slide-active').getAttribute('data-shopid');
    this.shopClick(shopid);
  }

  MarkupedPrice(cost,markup){
    return '$'+((parseFloat(cost)+parseFloat(markup)).toFixed(2));
  }

  designer(product){
    this.loading.show();
    this.shared.shopDetail=undefined;
    this.router.navigateByUrl("designer/" + product.theme_id + "/" + product.product_id);
    // this.getShopDetail(product);
  }
  getShopDetail(product) {
    var dat: { [k: string]: any } = {};
    dat.language_id = this.config.langId;
    dat.id = product.shop_id;
    this.config.postHttp2( 'shopDetail', dat).then((data: any) => {
      // this.loading.hide();
      if (data.success == 1) {
        this.shared.shopDetail=data.data;
        this.shared.shopDetail.themes.forEach((theme, index) => {
          if(theme.id==product.theme_id)
            this.shared.shopDetail.theme = theme;
        });
        this.getDesign(product);
      }
    });
  }
  getDesign(product){
    // this.loading.autoHide(1);
    this.shared.shopDetail.product=product;
    var dat: { [k: string]: any } = {};
    dat.product_id = product.product_id;
    dat.theme_id = product.theme_id;
    dat.language_id = this.config.langId;
    this.config.postHttp2( 'getDesigns', dat).then((data: any) => {
      // this.loading.hide();
      if (data.success == 1) {
        this.shared.shopDetail.collections=data.data;
        this.router.navigateByUrl("designer/" + product.theme_id + "/" + product.product_id);
      }
    });
  }
  async openProductDetail(product) {
    let modal = await this.modalCtrl.create({
      component: ProductDetailPage,
      componentProps: { data: product, primary_color: product.primary_color },
      cssClass: 'product-modal'
    });
    return await modal.present();
  }

  back(){
    this.router.navigateByUrl(this.config.currentRoute + "/shop/"+this.shared.shopDetail.id);
  }


  ngOnInit() {


    if (this.config.shopStyle == "bottomBulletsWhiteBackground") {
      this.slideOpts.pagination = {
        el: '.swiper-pagination',
        dynamicBullets: true,
      }
    }

    if (this.config.shopStyle == "progressBar") {
      this.slideOpts.pagination = {
        el: '.swiper-pagination',
        type: 'progressbar',
        progressbarOpposite: false
      }
    }

    if (this.config.shopStyle == "numberBullets") {
      this.slideOpts.pagination = {
        el: '.swiper-pagination',
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + '</span>';
        }
      }
    }
  }
}