import {Directive, HostListener, Input, OnInit} from '@angular/core';
import {DomController} from "@ionic/angular";

@Directive({
  selector: '[appFadeHeader]'
})
export class FadeHeaderDirective implements OnInit{

  @Input('appFadeHeader') toolbar:any;
  constructor(
      private domCtrl: DomController
  ) { }

  ngOnInit(): void {
    this.toolbar = this.toolbar.el;
  }

  @HostListener('ionScroll',['$event']) onContentScroll($event){
    let scrollTop =$event.detail.scrollTop;
     scrollTop= scrollTop-5;
    if(scrollTop >=255){
      scrollTop=255;
    }
    const hexDist = this.numHex(scrollTop);
    this.domCtrl.write(()=>{
      this.toolbar.style.setProperty('--background',`#2d2d2d${hexDist}`);
    })
  }
  numHex(s)
  {
    var a = parseInt(s).toString(16).toUpperCase();
    if ((a.length % 2) > 0) {
      a = "0" + a;
    }
    return a;
  }
}
